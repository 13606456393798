@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;0,700;1,300;1,500;1,700&display=swap');

@font-face {
  font-family: 'UKNumberPlate';
  src: url('../src/fonts/UKNumberPlate.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Courier New', monospace;
}